import {createBatchedApiQuery} from './api';
import {GetAccountsWithLogos} from '../../shared/api/definitions';
import {ObjectId} from 'bson';

export const AccountWithLogoQueryKeys = {
  all: ['accountWithLogo'] as const,
  account: (accountId: ObjectId) =>
    [...AccountWithLogoQueryKeys.all, accountId.toString()] as const,
};

export const useAccountWithLogo = createBatchedApiQuery(
  '/accountsWithLogos',
  GetAccountsWithLogos,
  AccountWithLogoQueryKeys.account,
  {
    staleTime: Infinity,
  }
);
