import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountQuery, SignalCountsResponse} from '../../shared/api/api';
import {useAccountView} from './AccountGrid';

interface UseSignalCountsProps {
  enabled: boolean;
  queryParams: AccountQuery | '';
}

export function useSignalCounts({queryParams, enabled}: UseSignalCountsProps) {
  const accountView = useAccountView();
  const makeApiCall = useKeyplayApi();
  return useQuery(
    ['signalCounts', accountView, JSON.stringify(queryParams)],
    () =>
      makeApiCall<SignalCountsResponse>('/signalCounts', {
        params: {
          query: queryParams,
        },
      }),
    {
      enabled,
      keepPreviousData: true,
      retry: 1,
    }
  );
}
