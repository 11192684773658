import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from './api';
import {
  CreateModelTestControlList,
  DeleteModelTestList,
  GetModelTestDefinition,
  GetModelTestListResults,
  GetModelTestResults,
  PostModelTestList,
  RefreshModelTestControlList,
} from '../../shared/api/definitions';
import {ObjectId} from 'bson';

export const ModelTestResultsCacheKey = 'modelTestResults';
export const ModelTestResultsKeys = {
  all: [ModelTestResultsCacheKey],
  market: (marketId: ObjectId) => [
    ...ModelTestResultsKeys.all,
    marketId.toString(),
  ],
} as const;
export const useGetModelTestResults = createApiQuery(
  '/modelTestResults',
  GetModelTestResults,
  ({marketId}) => ModelTestResultsKeys.market(marketId)
);

export const ModelTestListResultsCacheKey = 'modelTestListResults';
export const ModelTestListResultKeys = {
  all: [ModelTestListResultsCacheKey],
  market: (marketId: ObjectId) => [
    ...ModelTestListResultKeys.all,
    marketId.toString(),
  ],
  list: ({listId, marketId}: {marketId: ObjectId; listId: ObjectId}) => [
    ...ModelTestListResultKeys.market(marketId),
    listId.toString(),
  ],
} as const;

export const useGetModelTestListResults = createApiQuery(
  '/modelTestListResults',
  GetModelTestListResults,
  ({listId, marketId}) => ModelTestListResultKeys.list({marketId, listId}),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
  }
);

export const ModelTestDefinitionCacheKey = 'modelTestDefinition';
export const ModelTestDefinitionKeys = {
  all: [ModelTestDefinitionCacheKey],
  market: (marketId: ObjectId) => [
    ...ModelTestDefinitionKeys.all,
    marketId.toString(),
  ],
} as const;

export const useGetModelTestDefinition = createApiQuery(
  '/modelTestDefinition',
  GetModelTestDefinition,
  ({marketId}) => ModelTestDefinitionKeys.market(marketId),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
    refetchInterval: (data) =>
      data?.lists.some(({status}) => status.type === 'processing') ? 5_000 : 0,
  }
);

export const usePublishModelTestList = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/modelTestList', PostModelTestList, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ModelTestDefinitionKeys.all);
    },
  });
};

export const useCreateModelTestControlList = () => {
  const queryClient = useQueryClient();

  return useApiMutation(
    '/createModelTestControlList',
    CreateModelTestControlList,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ModelTestDefinitionKeys.all);
      },
    }
  );
};

export const useRefreshModelTestControlList = () => {
  const queryClient = useQueryClient();

  return useApiMutation(
    '/refreshModelTestControlList',
    RefreshModelTestControlList,
    {
      onSuccess: async (_, {marketId, listId}) => {
        await queryClient.invalidateQueries(
          ModelTestDefinitionKeys.market(marketId)
        );

        await queryClient.invalidateQueries(
          ModelTestListResultKeys.list({
            marketId,
            listId,
          })
        );
      },
    }
  );
};

export const useDeleteModelTestList = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/deleteModelTestList', DeleteModelTestList, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ModelTestDefinitionKeys.all);
    },
  });
};
