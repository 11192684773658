import React from 'react';
import {
  UnorderedList,
  ListItem,
  Flex,
  Image,
  Text,
  Button,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import {TrashCan} from '@carbon/icons-react';
import {ObjectId} from 'bson';
import {useAccountWithLogo} from '../hooks/api/accountWithLogo';
import _ from 'lodash';

export const AccountList = ({
  onRemoveAccount,
  selectedAccountIds,
}: {
  onRemoveAccount: (accountId: ObjectId) => void;
  selectedAccountIds: ObjectId[];
}) => {
  return (
    <UnorderedList listStyleType="none" marginLeft={0} spacing={2}>
      {selectedAccountIds.map((accountId) => (
        <AccountListItem
          key={accountId.toString()}
          accountId={accountId}
          onRemoveAccount={onRemoveAccount}
        />
      ))}
    </UnorderedList>
  );
};

const AccountListItem = ({
  accountId,
  onRemoveAccount,
}: {
  accountId: ObjectId;
  onRemoveAccount: (accountId: ObjectId) => void;
}) => {
  const {data: account, isLoading, isError} = useAccountWithLogo(accountId);
  if (isLoading) {
    return (
      <ListItem
        border="1px solid"
        borderColor="kgray.200"
        borderRadius="10px"
        height="60px"
        overflow="clip"
        p="3"
      >
        <Flex h="100%" alignItems="center" gap="4">
          <SkeletonCircle />
          <Skeleton height="10px" w="50%" />
        </Flex>
      </ListItem>
    );
  }

  if (!account || isError) {
    return null;
  }

  return (
    <ListItem
      key={account._id.toString()}
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="10px"
      p="3"
    >
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="4">
          <Image
            src={account.logoUrl}
            alt="icon"
            boxSize="32px"
            fallbackSrc="/accountFallback.png"
          />
          <Flex gap="2">
            <Text>{account.name}</Text>
            <Text fontWeight="thin" color="gray.500">
              {account.domain}
            </Text>
          </Flex>
        </Flex>
        <Button
          colorScheme="blue"
          onClick={() => onRemoveAccount(accountId)}
          variant="ghost"
        >
          <TrashCan />
        </Button>
      </Flex>
    </ListItem>
  );
};
