import React, {useMemo, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Box,
  Button,
  useModalContext,
} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {
  AiFieldBuilderStep,
  useAiFieldBuilderNavStore,
} from './AiFieldBuilderNav.state';
import {WrapperFlex} from '../../utils/scrolling';
import {AiFieldBuilderDefineFieldStep} from './AiFieldBuilderDefineFieldStep';
import {AiFieldBuilderSelectAccountsStep} from './AiFieldBuilderSelectAccountsStep';
import {AiFieldBuilderPreviewResultsStep} from './AiFieldBuilderPreviewResultsStep';
import {AiFieldBuilderModalHeader} from './AiFieldBuilderModalHeader';
import {FieldDefinitionProvider} from './FieldDefinitionContext';
import {ObjectId} from 'bson';

export const AiFieldBuilderModal = ({
  fieldDefinitionId,
  isOpen,
  onClose,
}: {
  fieldDefinitionId?: ObjectId;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {setStep} = useAiFieldBuilderNavStore();
  const resetState = () => {
    setStep('selectAccounts');
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={resetState}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <AiFieldBuilderContainer fieldDefinitionId={fieldDefinitionId} />
      </ModalContent>
    </Modal>
  );
};

const AiFieldBuilderContainer = ({
  fieldDefinitionId,
}: {
  fieldDefinitionId?: ObjectId;
}) => {
  const [localFieldDefinitionId, setLocalFieldDefinitionId] = useState<
    ObjectId | undefined
  >(fieldDefinitionId);

  return (
    <>
      {localFieldDefinitionId ? (
        <FieldDefinitionProvider fieldDefinitionId={localFieldDefinitionId}>
          <AiFieldBuilderModalContent />
        </FieldDefinitionProvider>
      ) : (
        <>
          <AiFieldBuilderModalHeader />
          <ModalBody as={Flex} flexDirection="column" p="0">
            <AiFieldBuilderTitleSection />
            <Box flex="1" position="relative">
              <WrapperFlex>
                <AiFieldBuilderDefineFieldStep
                  onContinue={(fieldDefinition) => {
                    setLocalFieldDefinitionId(fieldDefinition.id);
                  }}
                />
              </WrapperFlex>
            </Box>
          </ModalBody>
          <ModalCloseButton />
        </>
      )}
    </>
  );
};

const AiFieldBuilderModalContent = () => {
  const {publishChanges, serverState: fieldDefinition} =
    useAiFieldBuilderStore();
  const {step, setStep} = useAiFieldBuilderNavStore();
  const {onClose} = useModalContext();

  const Steps = useMemo(
    () =>
      ({
        defineField: (
          <AiFieldBuilderDefineFieldStep
            fieldDefinition={fieldDefinition}
            onContinue={() => setStep('selectAccounts')}
          />
        ),
        selectAccounts: <AiFieldBuilderSelectAccountsStep />,
        previewResults: <AiFieldBuilderPreviewResultsStep />,
      }) satisfies Record<AiFieldBuilderStep, React.ReactNode>,
    [fieldDefinition, setStep]
  );

  const saveDraftAndExit = () => {
    publishChanges.mutate({}, {onSuccess: onClose});
  };

  return (
    <>
      <AiFieldBuilderModalHeader />
      <ModalBody as={Flex} flexDirection="column" p="0">
        <AiFieldBuilderTitleSection>
          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isLoading}
            onClick={saveDraftAndExit}
            variant="outline"
          >
            Save Draft & Exit
          </Button>
        </AiFieldBuilderTitleSection>
        <Box flex="1" position="relative">
          <WrapperFlex>{Steps[step]}</WrapperFlex>
        </Box>
      </ModalBody>
      <ModalCloseButton />
    </>
  );
};

const AiFieldBuilderTitleSection = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Flex
      alignItems="baseline"
      borderBottom="1px solid"
      borderColor="kgray.200"
      h="90px"
      justifyContent="space-between"
      p="6"
    >
      <Text fontSize="2xl" fontWeight="normal">
        AI Field Builder
      </Text>
      {children}
    </Flex>
  );
};
