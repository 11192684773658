import React, {createContext, useContext} from 'react';
import {Market} from '../../shared/market';

const MarketContext = createContext<Market | null>(null);

export const MarketProvider = ({
  children,
  market,
}: React.PropsWithChildren<{market: Market}>) => {
  return (
    <MarketContext.Provider value={market}>{children}</MarketContext.Provider>
  );
};

export const useMarketFromContext = () => {
  const market = useContext(MarketContext);
  if (!market) {
    throw new Error('MarketContext not found');
  }

  return market;
};
