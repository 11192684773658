import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {getFiltersForView} from '../../shared/api/helpers';
import {ScoredAccountsResponse} from '../../shared/api/responses';

export function useDiscoverCounts() {
  const makeApiCall = useKeyplayApi();
  const {data} = useQuery<ScoredAccountsResponse>(
    ['scoredAccountsTables'],
    async () =>
      makeApiCall<ScoredAccountsResponse>(
        // TODO: probably makes sense to have a separate server endpoint to get these counts?
        '/scoredAccounts',
        {
          params: {
            query: {
              filter: {
                ...getFiltersForView('recommended'),
              },
              sort: {
                field: 'overallFit',
                order: 'desc',
              },
              limit: 1,
            },
          },
        }
      ),
    {
      keepPreviousData: true,
    }
  );

  const totalCount = data?.totalLength;
  const scoringRun = data?.scoringRun;

  return {
    totalCount,
    samSize: scoringRun?.samSize ?? 0,
  };
}
