import {z} from 'zod';

export const ApiAudience = 'https://api.keyplay.io';
export const LoginUrl = 'https://login.keyplay.io';
export const Auth0ClientId = 'sLDWqMFa3sT7E9xbgLPi9IDqUR8c406C';
export const Auth0Domain = 'dev-tdz6t5em.us.auth0.com';
export const KeyplayAppId = 'WxP65TT2EdH7tQZJKr6GEZX6ZYYxXGim';

// Defined in custom action:
// https://manage.auth0.com/dashboard/us/dev-tdz6t5em/actions/library/details/c4bb6eb6-c5d0-4f6e-b395-e3fd84ee264b
type KeyplayCustomClaims = {
  'keyplay/customerId'?: string;
  'keyplay/email'?: string;
  'keyplay/globalRoles'?: string[];
  'keyplay/isKeyplayAdmin'?: boolean;
  'keyplay/isTemporary'?: boolean;
};

export function getCustomClaims(obj: {[prop: string]: unknown}) {
  const claims = obj as KeyplayCustomClaims;
  return {
    customerId: claims['keyplay/customerId'],
    email: claims['keyplay/email'],
    globalRoles: claims['keyplay/globalRoles'],
    isKeyplayAdmin: !!claims['keyplay/isKeyplayAdmin'],
    isTemporarayMembership: !!claims['keyplay/isTemporary'],
  };
}

export const KeyplayUserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  name: z.string().optional(),
  picture: z.string().optional(),
  state: z.enum(['active', 'invited']),
});
export type KeyplayUser = z.infer<typeof KeyplayUserSchema>;
