import {ObjectId} from 'bson';
import {
  GetEnrichmentPreviewResultsDefinition,
  RunEnrichmentPreviewDefinition,
} from '../../shared/api/definitions';
import {createApiQuery, useApiMutation} from './api';
import {TaskStatusQueryKeys, useTaskStatus} from './tasks';
import {useQueryClient} from '@tanstack/react-query';

const EnrichmentPreviewQueryKeys = {
  all: ['enrichmentPreviewResults'],
  version: ({
    fieldDefinitionId,
    versionId,
  }: {
    fieldDefinitionId: ObjectId;
    versionId: ObjectId;
  }) => [
    ...EnrichmentPreviewQueryKeys.all,
    fieldDefinitionId.toString(),
    versionId.toString(),
  ],
} as const;

export const useGetEnrichmentPreviewResults = createApiQuery(
  '/enrichmentPreviewResults',
  GetEnrichmentPreviewResultsDefinition,
  ({fieldDefinitionId, versionId}) =>
    EnrichmentPreviewQueryKeys.version({fieldDefinitionId, versionId})
);

export const useRunEnrichmentPreview = () => {
  const queryClient = useQueryClient();
  return useApiMutation(
    '/runEnrichmentPreview',
    RunEnrichmentPreviewDefinition,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: TaskStatusQueryKeys.task('accountEnrichmentPreview'),
        });
      },
    }
  );
};

export const useIsEnrichmentPreviewTaskRunning = () => {
  const queryClient = useQueryClient();
  return useTaskStatus({
    taskType: 'accountEnrichmentPreview',
    onTaskComplete: () => {
      queryClient.invalidateQueries({
        queryKey: EnrichmentPreviewQueryKeys.all,
      });
    },
  });
};
