import {ObjectId} from 'bson';
import {z} from 'zod';
import {ObjectIdSchema} from './zod';

export const ModelTestDefinitionSchema = z.object({
  timestamp: z.date(),
  customerId: z.instanceof(ObjectId),
  marketId: z.instanceof(ObjectId),
  lists: z.array(ObjectIdSchema),
});
export type ModelTestDefinition = z.infer<typeof ModelTestDefinitionSchema>;
