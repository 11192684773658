import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountTypeaheadResponse} from '../../shared/api/responses';

export function useAccountTypeahead(search: string) {
  const keyplayApi = useKeyplayApi();

  return useQuery<AccountTypeaheadResponse>(
    ['accountTypeahead', search],
    () =>
      keyplayApi('/accountTypeahead', {
        params: {search},
        method: 'GET',
      }),
    {
      enabled: !!search,
      refetchOnWindowFocus: false,
    }
  );
}
