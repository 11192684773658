import React from 'react';
import {Heading, Text} from '@chakra-ui/react';
import {ExportModalSection} from './ExportModalSection';
import {UseMutationResult} from '@tanstack/react-query';
import {
  ExportResponse,
  ExportRequest,
  PageScrapeExportMaxRows,
} from '../../../shared/api/api';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';

interface MyAccountsExportProps {
  numberOfAccounts?: number;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
}

export const MyAccountsExport = ({
  exportMutation,
  numberOfAccounts,
}: MyAccountsExportProps) => {
  const isAdmin = useIsKeyplayAdmin();
  const numAccounts = Number(numberOfAccounts ?? 0).toLocaleString();
  const pageExportDisabledText =
    (numberOfAccounts ?? 0) > PageScrapeExportMaxRows
      ? `Export is limited to ${PageScrapeExportMaxRows} rows`
      : undefined;

  return (
    <>
      <ExportModalSection
        buttonText="Export CSV"
        type="filtered_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          Accounts in Current View
        </Heading>
        <Text>
          {numAccounts} accounts matching your current filters, keyed by{' '}
          <Text as="u">domain</Text>.
        </Text>
      </ExportModalSection>
      <ExportModalSection
        buttonText="Export CSV"
        type="all_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          All Accounts
        </Heading>
        <Text>
          All accounts, keyed by <Text as="u">domain</Text>.
        </Text>
      </ExportModalSection>
      <ExportModalSection
        buttonText="Export CSV"
        type="crm_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          CRM Enrichment
        </Heading>
        <Text>
          All accounts, keyed by associated <Text as="u">CRM ID</Text>.
        </Text>
      </ExportModalSection>
      {isAdmin && (
        <ExportModalSection
          disabledText={pageExportDisabledText}
          buttonText="Export CSV"
          type="page_source"
          bgColor={'kyellow.50'}
          exportMutation={exportMutation}
        >
          <Heading fontSize="lg" fontWeight="bold" mb={2}>
            Page Source
          </Heading>
          <Text>{numAccounts} accounts matching your current filters</Text>
        </ExportModalSection>
      )}
      {isAdmin && (
        <ExportModalSection
          disabledText={pageExportDisabledText}
          buttonText="Export CSV"
          type="page_text"
          bgColor={'kyellow.50'}
          exportMutation={exportMutation}
        >
          <Heading fontSize="lg" fontWeight="bold" mb={2}>
            Page Text
          </Heading>
          <Text>{numAccounts} accounts matching your current filters</Text>
        </ExportModalSection>
      )}
    </>
  );
};
