import React, {useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  SimpleGrid,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useKeyplayApi} from '../../context/ApiContext';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {ApiKeyResponse} from '../../shared/api/api';
import {ApiKey, CustomerOptions} from '../../shared/customer';
import {Copy, TrashCan} from '@carbon/icons-react';
import {DeleteApiKeyModal} from './DeleteApiKeyModal';
import {useIsFeatureEnabled} from '../../hooks/api/metadata';
import {ObjectId} from 'bson';

const columnHelper = createColumnHelper<ApiKey>();

const ApiKeyInfo = ({keys}: {keys: ApiKeyResponse}) => {
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const toast = useToast();

  const [apiKeyIdToDelete, setApiKeyIdToDelete] = useState<ObjectId>();

  const columns = [
    columnHelper.accessor('key', {
      cell: (info) => {
        const keyValue = '*';
        const trailingDigits = info.getValue().slice(-8);
        return keyValue.repeat(18) + trailingDigits;
      },
      header: 'Key',
      id: 'key',
    }),
    columnHelper.accessor('key', {
      header: '',
      id: 'button',
      cell: (info) => {
        const key = info.getValue();
        return (
          <Button
            key="generate"
            fontWeight="500"
            fontSize="14"
            colorScheme="kbuttonblue"
            variant="outline"
            onClick={() => {
              toast({
                status: 'success',
                title: 'Key copied to clipboard.',
                duration: 2_000,
                isClosable: true,
              });
              navigator.clipboard.writeText(key);
            }}
          >
            <Copy />
          </Button>
        );
      },
    }),
    columnHelper.accessor('generated', {
      cell: (info) => info.getValue().toLocaleString(),
      id: 'generated',
      header: 'Generated',
    }),
    columnHelper.accessor('lastUsed', {
      cell: (info) => {
        const lastUsed = info.getValue();
        if (!lastUsed) {
          return '\u2014';
        }

        return lastUsed.toLocaleString();
      },
      id: 'lastused',
      header: 'Last Used',
    }),
    columnHelper.accessor('_id', {
      header: '',
      id: 'delete',
      cell: (info) => {
        const id = info.getValue();
        return (
          <Button
            key="delete"
            fontWeight="500"
            fontSize="14"
            colorScheme="red"
            variant="outline"
            onClick={() => {
              setApiKeyIdToDelete(id);
              onDeleteModalOpen();
            }}
          >
            <TrashCan />
          </Button>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: keys,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <DeleteApiKeyModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        apiKeyIdToDelete={apiKeyIdToDelete}
      />
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const Api = () => {
  const makeApiCall = useKeyplayApi();
  const apiKey = useIsFeatureEnabled('apiKey');
  const {data: apiKeys, isSuccess: apiKeysIsSuccess} = useQuery(
    ['apiKeys'],
    () => makeApiCall<ApiKeyResponse>('/apiKeys')
  );
  const {data: options, isSuccess: optionsIsSuccess} = useQuery(
    ['options'],
    () => makeApiCall<CustomerOptions>('/options')
  );

  const queryClient = useQueryClient();
  const toast = useToast();
  const createApiKey = useMutation<unknown>({
    mutationFn: () => {
      return makeApiCall(
        '/apiKeys',
        {
          method: 'POST',
        },
        {toastOnError: true}
      );
    },
    onSuccess: async () => {
      toast({
        status: 'success',
        title: 'API key created.',
        duration: 2_000,
        isClosable: true,
      });
      await queryClient.invalidateQueries(['apiKeys']);
    },
  });

  const addOnEnrich = useMutation<void, unknown, {value: boolean}>({
    mutationFn: ({value}) => {
      return makeApiCall(
        '/options/addOnEnrich',
        {
          method: 'POST',
          data: {value},
        },
        {toastOnError: true}
      );
    },
    onMutate: async ({value: addOnEnrich}) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({queryKey: ['options']});

      // Optimistically update to the new value
      queryClient.setQueryData<CustomerOptions>(['options'], (old) => ({
        ...(old || {}),
        addOnEnrich,
      }));
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Configuration updated.',
        duration: 2_000,
        isClosable: true,
      });
    },
    // Always refetch after error or success.
    onSettled: async () => {
      await queryClient.invalidateQueries({queryKey: ['options']});
    },
  });

  return (
    <Flex direction="column">
      <Box>
        <Box fontSize={20} fontWeight={500}>
          Keyplay API
        </Box>
        <br />
        <Box>
          The Keyplay enrichment API will return signals and scores for any
          domain. The API can be integrated with iPaaS tools like Zapier or Make
          to enrich inbound leads in your CRM or MAP.
        </Box>
      </Box>
      {(apiKey || (apiKeysIsSuccess && apiKeys.length > 0)) && (
        <>
          <Box
            borderColor="#e8e8e8"
            borderWidth="1px"
            borderRadius="8px"
            p={6}
            mt={8}
          >
            <Flex>
              <Box fontWeight={500} pl={4} display="flex" alignItems="top">
                API Keys
              </Box>
              <Spacer />
              {apiKey && (
                <Button
                  key="approve"
                  fontWeight="500"
                  mr={2}
                  colorScheme="kbuttonblue"
                  onClick={() => createApiKey.mutate()}
                  isLoading={createApiKey.isLoading}
                >
                  Generate
                </Button>
              )}
            </Flex>
            <Box mt="8">
              {apiKeysIsSuccess ? (
                apiKeys.length ? (
                  <ApiKeyInfo keys={apiKeys} />
                ) : apiKey ? (
                  <Box margin="auto" pt={4} pl={4}>
                    <i>No API keys found, try generating one.</i>
                  </Box>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box
            borderColor="#e8e8e8"
            borderWidth="1px"
            borderRadius="8px"
            p={6}
            mt={8}
          >
            <Box fontWeight={500} pl={4} display="flex" alignItems="center">
              API Settings
            </Box>
            <Box pl={4} pt={8}>
              <SimpleGrid columns={2} gridTemplateColumns="min-content auto">
                <Checkbox
                  alignItems="top"
                  pt={0.5}
                  isChecked={options?.addOnEnrich}
                  onChange={(e) =>
                    addOnEnrich.mutate({value: e.target.checked})
                  }
                  isDisabled={!optionsIsSuccess}
                />
                <Box pl={4}>
                  <Box fontWeight={500}>
                    When a new account is received via the API, add it to
                    Keyplay Active Accounts
                  </Box>
                  <Box pt={2} fontStyle="italic">
                    We recommend turning this ON if you are using the API to
                    enrich your CRM account objects. For any other use cases
                    this setting should be OFF.
                  </Box>
                </Box>
              </SimpleGrid>
            </Box>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Api;
