import {useQueryClient} from '@tanstack/react-query';
import {useApiMutation} from './api';
import {PostMarket} from '../../shared/api/definitions';
import {ModelTestListResultKeys, ModelTestResultsKeys} from './scoringModel';
import {MetadataQueryKey} from '../../context/MetadataContext';
import {ScoringInfoQueryKey} from './scoringInfo';

export const MarketQueryKeys = {
  all: ['markets'],
} as const;

export const usePublishMarket = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/market', PostMarket, {
    onSuccess: async (
      _updatedMarket,
      {marketId, scoringModel, triggerRefresh}
    ) => {
      const keysToInvalidate = [
        // Refetch the list of markets
        [MetadataQueryKey],
        // Invalidate model test results
        ...(scoringModel
          ? [
              ModelTestListResultKeys.market(marketId),
              ModelTestResultsKeys.market(marketId),
            ]
          : []),
        ...(triggerRefresh ? [[ScoringInfoQueryKey]] : []),
      ];

      await Promise.all(
        keysToInvalidate.map((queryKeys) =>
          queryClient.invalidateQueries(queryKeys)
        )
      );
    },
  });
};
