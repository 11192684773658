import React, {useContext} from 'react';
import {
  Box,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import {isKeyplayAdmin} from '../../../lib/user';
import {AdminDiscoverExport} from './AdminDiscoverExport';
import {MyAccountsExport} from './MyAccountsExport';
import {useExportMutation} from '../useExportMutation';
import {useIsMyAccountsView} from '../AccountGrid.controlstate';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';

const IncludeSpecialFieldsContext = React.createContext<boolean>(false);

function IncludeSpecialFieldsProvider({
  includeSpecialFields,
  children,
}: React.PropsWithChildren<{
  includeSpecialFields: boolean;
}>) {
  return (
    <IncludeSpecialFieldsContext.Provider value={includeSpecialFields}>
      {children}
    </IncludeSpecialFieldsContext.Provider>
  );
}

export function useIncludeSpecialFields() {
  return useContext(IncludeSpecialFieldsContext);
}

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfAccounts?: number;
}

export const ExportModal = ({
  isOpen,
  onClose,
  numberOfAccounts,
}: ExportModalProps) => {
  const {user} = useAuth0();
  const isAdmin = useIsKeyplayAdmin();
  const isMyAccountsView = useIsMyAccountsView();
  const [includeSpecialFields, setIncludeSpecialFields] = React.useState(false);

  const showAdminDiscoverModal = !isMyAccountsView && isKeyplayAdmin(user);

  const exportMutation = useExportMutation({
    onSuccess: (result, request) => {
      if (request.mode === 'async') {
        onClose();
      }
    },
  });

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          What would you like to export?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <IncludeSpecialFieldsProvider
            includeSpecialFields={includeSpecialFields}
          >
            {showAdminDiscoverModal ? (
              <AdminDiscoverExport
                exportMutation={exportMutation}
                numberOfAccounts={numberOfAccounts}
              />
            ) : (
              <MyAccountsExport
                exportMutation={exportMutation}
                numberOfAccounts={numberOfAccounts}
              />
            )}
          </IncludeSpecialFieldsProvider>
        </ModalBody>
        {isAdmin && (
          <ModalFooter>
            <Box display="flex" alignItems="center">
              <Checkbox
                id="include-admin-fields"
                isChecked={includeSpecialFields}
                onChange={(e) => setIncludeSpecialFields(e.target.checked)}
              >
                Include Special Fields
              </Checkbox>
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
