import {ObjectId} from 'bson';
import {createStore, StoreApi, useStore} from 'zustand';
import {AiFieldDefinition, isFieldDefinition} from '../../../shared/enrichment';
import {useMutation} from '@tanstack/react-query';
import {useSaveFieldDefinition} from '../../../hooks/api/fieldDefinitions';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';
import {fromEntries} from '../../../shared/util';
import _ from 'lodash';
import produce from 'immer';
import {useEffect} from 'react';

interface AiFieldBuilderState {
  updateServerState: ({
    fieldDefinition,
    updateMode,
  }: {
    fieldDefinition: AiFieldDefinition;
    updateMode: 'always' | 'ifNoChanges';
  }) => void;
  serverState: AiFieldDefinition;

  hasChanges: () => boolean;

  selectedAccountIds: ObjectId[];
  addAccount: (accountId: ObjectId) => void;
  removeAccount: (accountId: ObjectId) => void;

  versions: {id: ObjectId; label: string; timestamp: Date}[];
  versionConfigs: Record<string, AiFieldDefinition['config']>;
  updateVersionConfig: (
    versionId: ObjectId,
    config: AiFieldDefinition['config']
  ) => void;
}

const createAiFieldBuilderStore = (fieldDefinition: AiFieldDefinition) =>
  createStore<AiFieldBuilderState>()((set, get) => ({
    updateServerState: ({fieldDefinition, updateMode}) => {
      if (get().hasChanges() && updateMode === 'ifNoChanges') {
        return;
      }

      set({
        serverState: fieldDefinition,
        selectedAccountIds: fieldDefinition.preview?.accounts ?? [],
        versions:
          fieldDefinition.preview?.versions.map((v) =>
            _.pick(v, ['id', 'label', 'timestamp'])
          ) ?? [],
        versionConfigs: fromEntries(
          fieldDefinition.preview?.versions.map((version) => [
            version.id.toString(),
            version.config,
          ]) ?? []
        ),
      });
    },
    serverState: fieldDefinition,

    hasChanges: () => {
      const {serverState, selectedAccountIds, versions, versionConfigs} = get();
      const localVersions = versions.map((version) => ({
        ...version,
        config: versionConfigs[version.id.toString()],
      }));

      return (
        !_.isEqual(serverState.preview?.accounts, selectedAccountIds) ||
        !_.isEqual(serverState.preview?.versions, localVersions)
      );
    },

    selectedAccountIds: fieldDefinition.preview?.accounts.map((id) => id) ?? [],
    addAccount: (accountId) =>
      set({selectedAccountIds: [...get().selectedAccountIds, accountId]}),
    removeAccount: (accountId) =>
      set({
        selectedAccountIds: get().selectedAccountIds.filter(
          (id) => !id.equals(accountId)
        ),
      }),

    versions:
      fieldDefinition.preview?.versions.map((v) =>
        _.pick(v, ['id', 'label', 'timestamp'])
      ) ?? [],
    versionConfigs: fromEntries(
      fieldDefinition.preview?.versions.map((v) => [
        v.id.toString(),
        v.config,
      ]) ?? []
    ),
    updateVersionConfig: (versionId, config) =>
      set(
        produce<AiFieldBuilderState>((state) => {
          state.versionConfigs[versionId.toString()] = config;
        })
      ),
  }));

// Store for each field definition
const aiFieldBuilderStore: Record<string, StoreApi<AiFieldBuilderState>> = {};
export const useAiFieldBuilderStore = () => {
  const fieldDefinition = useFieldDefinitionFromContext();
  const fieldDefinitionId = fieldDefinition.id.toString();

  aiFieldBuilderStore[fieldDefinitionId] ??=
    createAiFieldBuilderStore(fieldDefinition);
  const store = useStore(aiFieldBuilderStore[fieldDefinitionId]);

  const saveFieldDefinition = useSaveFieldDefinition();
  const publishChanges = useMutation({
    mutationFn: async ({
      publishVersionId,
    }: {publishVersionId?: ObjectId} = {}) => {
      const fieldDefinition = {
        ...store.serverState,
        preview: {
          accounts: store.selectedAccountIds,
          versions: store.versions.map((version) => ({
            ...version,
            config: store.versionConfigs[version.id.toString()],
          })),
        },
      };

      if (!isFieldDefinition(fieldDefinition)) {
        throw new Error('Invalid field definition');
      }

      return saveFieldDefinition.mutateAsync({
        fieldDefinition,
        ...(publishVersionId ? {publishVersionId} : {}),
      });
    },
    onSuccess: (fieldDefinition) => {
      if (fieldDefinition.type !== 'ai') {
        throw new Error('Field definition is not an AI field');
      }

      store.updateServerState({fieldDefinition, updateMode: 'always'});
    },
  });

  const {updateServerState} = store;
  useEffect(() => {
    updateServerState({fieldDefinition, updateMode: 'ifNoChanges'});
  }, [fieldDefinition, updateServerState]);

  return {
    ...store,
    publishChanges,
  };
};
