import {ObjectId} from 'bson';
import {StaticAccountData} from './account';
import {ScoredAccount, Tag} from './scoredAccounts';
import {WithId} from './util';
import {KeyplayScoringSignal} from './signals';
import {CrmRecord} from './crm';

export type SimilarAccountLookup = {
  _id: ObjectId;
  domain: string;
};
export type ExportData = {
  tags?: WithId<Tag>[];
  scoringData?: {
    signals: KeyplayScoringSignal[];
  };
  crmRecord?: WithId<CrmRecord>;
  scoredAccount?: WithId<ScoredAccount>;
  mostSimilarAccount?: SimilarAccountLookup;
  // HACK: Account isn't in shared yet
  account?: {staticData?: StaticAccountData};
};

export type WithTags<T> = T & {
  tags: WithId<Tag>[];
};
export type WithStaticAccountData<T> = T & {
  staticAccountData?: StaticAccountData;
};
export type WithMostSimilarAccount<T> = T & {
  mostSimilarAccount?: SimilarAccountLookup;
};

export type WithAllLookups<T> = WithStaticAccountData<
  WithMostSimilarAccount<WithTags<T>>
>;

export const MaxClayExportSize = 5_000;
export const ClayExportRegex = /^https:\/\/api.clay.com/;
