import React from 'react';
import {useSignalBuilderStore} from './SignalBuilder.state';
import {Box, Flex, GridItem} from '@chakra-ui/react';
import {Label} from './helpers';
import Select from 'react-select';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';

type BooleanEvalType = 'isTrue' | 'isFalse';

const booleanOptions = [
  {value: 'isTrue', label: 'Is True'},
  {value: 'isFalse', label: 'Is False'},
] as const;

export const BooleanSignalBuilder = () => {
  const selectedField = useFieldDefinitionFromContext();
  const {evalFn, label, setEvalFn, setLabel} = useSignalBuilderStore();

  if (evalFn && evalFn.type !== 'isTrue' && evalFn.type !== 'isFalse') {
    throw new Error('Invalid evalFn type');
  }

  const update = (type: BooleanEvalType) => {
    setEvalFn({
      type,
      field: selectedField.id,
    });
    setLabel(
      type === 'isTrue'
        ? `Has ${selectedField.label}`
        : `No ${selectedField.label}`
    );
  };

  return (
    <>
      <GridItem>
        <Label>Signal</Label>
      </GridItem>
      <GridItem>
        <Label>Preview</Label>
      </GridItem>

      <GridItem as={Flex} alignItems="center" flexDirection="row" gap="4">
        <Box w="100%">
          <Select
            isMulti={false}
            options={booleanOptions}
            value={booleanOptions.find(
              (option) => option.value === evalFn?.type
            )}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }

              update(newValue.value);
            }}
          />
        </Box>
      </GridItem>
      <GridItem alignSelf="center">{label}</GridItem>
    </>
  );
};
