import {ObjectId} from 'bson';
import {createStore, StoreApi, useStore} from 'zustand';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';
import {useEffect} from 'react';

interface SelectedVersionState {
  selectedVersionId: ObjectId;
  setSelectedVersionId: (versionId: ObjectId) => void;
}

const createSelectedVersionStore = (selectedVersionId: ObjectId) =>
  createStore<SelectedVersionState>()((set) => ({
    selectedVersionId,
    setSelectedVersionId: (versionId) => set({selectedVersionId: versionId}),
  }));

const selectedVersionStore: Record<string, StoreApi<SelectedVersionState>> = {};
export const useSelectedVersionStore = () => {
  const fieldDefinition = useFieldDefinitionFromContext();
  const fieldDefinitionId = fieldDefinition.id.toString();

  const versions = fieldDefinition.preview?.versions;
  if (!versions?.length) {
    throw new Error('No versions found for field definition');
  }

  const firstVersionId = versions[0].id;
  selectedVersionStore[fieldDefinitionId] ??=
    createSelectedVersionStore(firstVersionId);
  const store = useStore(selectedVersionStore[fieldDefinitionId]);

  const {selectedVersionId, setSelectedVersionId} = store;
  useEffect(() => {
    // If the selected version is not in the list of server versions, set to the first version
    if (!versions.find((version) => version.id.equals(selectedVersionId))) {
      setSelectedVersionId(firstVersionId);
    }
  }, [firstVersionId, selectedVersionId, setSelectedVersionId, versions]);

  return store;
};
