import React from 'react';
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {TwoColumnGrid} from './helpers';
import {AccountTypeahead} from '../../AccountTypeahead';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {AccountList} from '../../AccountList';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {AiFieldBuilderPreviewTable} from './AiFieldBuilderPreviewTable';

export const AiFieldBuilderSelectAccountsStep = () => {
  const {selectedAccountIds, addAccount, removeAccount, publishChanges} =
    useAiFieldBuilderStore();
  const {nextStep, prevStep} = useAiFieldBuilderNavStore();

  const onContinue = () => {
    publishChanges.mutate({}, {onSuccess: nextStep});
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <Flex direction="column" flex="1" gap="2" p="6">
                <Text fontSize="xl">Choose accounts to preview</Text>
                <Text fontSize="md" color="kgray.300">
                  Choose up to 10 accounts.
                </Text>
                <Flex direction="column" gap="2">
                  <AccountTypeahead
                    disabledTooltip="Max preview accounts reached"
                    isDisabled={selectedAccountIds.length >= 10}
                    onSelect={addAccount}
                    placeholder="Search for an account..."
                    selected={selectedAccountIds}
                  />
                  <AccountList
                    onRemoveAccount={removeAccount}
                    selectedAccountIds={selectedAccountIds}
                  />
                </Flex>
              </Flex>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4}>
          <Button variant="ghost" onClick={prevStep}>
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isDisabled={!selectedAccountIds.length}
            isLoading={publishChanges.isLoading}
            onClick={onContinue}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <Flex p="6" direction="column" gap="8">
        <Flex direction="column" gap="2">
          <Text fontSize="xl">Preview</Text>
          <Text fontSize="md" color="kgray.300">
            Choose accounts that will help you review your AI Analyst&apos;s
            answers
          </Text>
        </Flex>
        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <AiFieldBuilderPreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </Flex>
    </TwoColumnGrid>
  );
};
