import {create} from 'zustand';
import {ScoringCategory} from '../../../shared/signals';
import {SignalEvalFn} from '../../../shared/signalDefinition';

type Step = 'selectField' | 'defineSignal' | 'done';

interface SignalBuilderNavState {
  step: Step;
  setStep: (step: Step) => void;
}

export const useSignalBuilderNavStore = create<SignalBuilderNavState>(
  (set) => ({
    step: 'selectField',
    setStep: (step) => set({step}),
  })
);

interface SignalBuilderState {
  signalCategory: ScoringCategory | null;
  setSignalCategory: (category: ScoringCategory | null) => void;

  label: string;
  setLabel: (label: string) => void;

  evalFn: SignalEvalFn | null;
  setEvalFn: (evalFn: SignalEvalFn | null) => void;

  reset: () => void;
}

export const useSignalBuilderStore = create<SignalBuilderState>((set) => ({
  signalCategory: null,
  setSignalCategory: (category) => set({signalCategory: category}),

  evalFn: null,
  setEvalFn: (evalFn) => set({evalFn}),

  label: '',
  setLabel: (label) => set({label}),

  reset: () =>
    set({
      evalFn: null,
      label: '',
      signalCategory: null,
    }),
}));
