import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {GetUsersResponse} from '../../shared/api/api';
import {GetKeyplayUsers} from '../../shared/api/definitions';
import {createBatchedApiQuery} from './api';

export const UsersQueryKey = 'users';

export function useUsers() {
  const makeApiCall = useKeyplayApi();
  return useQuery([UsersQueryKey], () =>
    makeApiCall<GetUsersResponse>('/customer/users')
  );
}

export function useInviteMutation() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (email: string) =>
      makeApiCall(
        '/customer/invite',
        {
          method: 'POST',
          data: {email},
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries([UsersQueryKey]);
    },
  });
}

export const KeyplayUserQueryKeys = {
  all: ['keyplayUser'] as const,
  user: (userId: string) => [...KeyplayUserQueryKeys.all, userId] as const,
};
export const useKeyplayUser = createBatchedApiQuery(
  '/debug/users',
  GetKeyplayUsers,
  KeyplayUserQueryKeys.user,
  {
    staleTime: Infinity,
  }
);
