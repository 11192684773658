import React from 'react';
import {Box, FlexProps, Flex, Button, Tooltip} from '@chakra-ui/react';
import {
  ExportType,
  AccountQuery,
  ExportRequest,
  ExportResponse,
  isSpecialFieldExportType,
  isAccountQueryExportType,
} from '../../../shared/api/api';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {UseMutationResult} from '@tanstack/react-query';
import {useIncludeSpecialFields} from './ExportModal';

type ExportModalSectionProps = FlexProps & {
  children: React.ReactNode;
  disabledText?: string;
  buttonText: string;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
  numberOfAccounts?: number;
  type: ExportType;
  mode?: 'sync' | 'async';
};

export const ExportModalSection = ({
  children,
  buttonText,
  type,
  mode = 'async',
  disabledText,
  exportMutation,
  ...props
}: ExportModalSectionProps) => {
  const accountQuery = useAccountQueryParams();
  const includeSpecialFields = useIncludeSpecialFields();

  const handleExportClick = async () => {
    let query: AccountQuery | undefined;
    if (isAccountQueryExportType(type)) {
      query = accountQuery;
    }

    exportMutation.mutate({
      type,
      query,
      mode,
      includeSpecialFields,
    });
  };

  let isDisabled: boolean | undefined;
  let tooltipLabel: string | undefined;

  if (disabledText) {
    isDisabled = true;
    tooltipLabel = disabledText;
  } else if (includeSpecialFields && !isSpecialFieldExportType(type)) {
    isDisabled = true;
    tooltipLabel = 'This export does not support special fields';
  }

  return (
    <Flex border="1px" borderColor="kgray.200" p={4} mb={4} {...props}>
      <Box flexGrow="1">{children}</Box>
      <Tooltip label={tooltipLabel} isDisabled={!isDisabled}>
        <Button
          alignSelf="center"
          border="1px"
          colorScheme="kbuttonblue"
          variant="ghost"
          onClick={handleExportClick}
          isLoading={exportMutation.isLoading}
          isDisabled={isDisabled}
          _hover={{
            bg: 'kblue.50',
          }}
          fontSize={14}
          fontWeight={500}
          px={6}
          py={3}
          transitionDuration="200ms"
          transitionProperty="background-color,border-color,color"
        >
          {buttonText}
        </Button>
      </Tooltip>
    </Flex>
  );
};
