import {z} from 'zod';
import {GenericTaskSchema} from '../task';
import {ObjectId} from 'bson';
import {MarketSchema, SamDefinitionSchema, ScoringModelSchema} from '../market';
import {OnboardingTemplateRequestSchema} from '../onboarding';
import {
  CreditPriceResponseSchema,
  GetModelTestResultsResponseSchema,
} from './responses';
import {PlanSchema} from '../customer';
import {AccountQuerySchema, AccountSelectionSchema} from './api';
import {TierCountsSchema} from '../scoring';
import {
  AccountWithLogo,
  AccountWithLogoSchema,
  ScoringRunSchema,
} from '../scoredAccounts';
import {ModelTestDefinitionSchema} from '../modelTest';
import {TestListName} from '../validation';
import {
  AiBooleanFieldDefinitionSchema,
  AiCategoryFieldDefinitionSchema,
  AiRatingFieldDefinitionSchema,
  CrmFieldDefinitionSchema,
  FieldDefinitionSchema,
} from '../enrichment';
import {SignalDefinitionSchema} from '../signalDefinition';
import {ObjectIdSchema} from '../zod';
import {KeyplayUser, KeyplayUserSchema} from '../auth';
import {TestListSchema} from '../testLists';

const WithIdSchema = {
  _id: z.instanceof(ObjectId),
};

export type ApiOptions = {
  access: 'admin' | 'paid' | 'all';
};

// The ApiDefinition is the contract between the client the server for a given route.
export interface ApiDefinition<RequestData = unknown, ResponseData = unknown> {
  method: 'get' | 'post';
  options: ApiOptions;
  requestDataSchema: z.ZodType<RequestData>;
  responseDataSchema: z.ZodType<ResponseData>;
}

export interface BatchedApiDefinition<
  BatchedRequestData,
  BatchedResponseData,
  Item,
  ItemResponseData,
> extends ApiDefinition<BatchedRequestData, BatchedResponseData> {
  maxBatchSize?: number;
  itemSchema: z.ZodType<Item>;
  itemResponseDataSchema: z.ZodType<ItemResponseData>;

  createParams: (items: Item[]) => BatchedRequestData;
  resolveItem: (
    items: BatchedResponseData,
    item: Item
  ) => ItemResponseData | null;
}

export const GetTasks = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    partialCustomerName: z.string().optional(),
  }),
  responseDataSchema: GenericTaskSchema.array(),
} satisfies ApiDefinition;

export const CancelTask = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    taskName: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const SetOnboardingTemplate = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: OnboardingTemplateRequestSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const BulkFindLookalikes = {
  method: 'post', // POST instead of GET due to the potential size of the request
  options: {access: 'all'},
  requestDataSchema: z.string().array(),
  responseDataSchema: ObjectIdSchema.array(),
} satisfies ApiDefinition;

export const GetCreditPrices = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({}),
  responseDataSchema: CreditPriceResponseSchema,
} satisfies ApiDefinition;

export const CreateCheckoutSession = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    priceId: z.string(),
  }),
  responseDataSchema: z.object({
    url: z.string(),
  }),
} satisfies ApiDefinition;

export const ClaimBonusCode = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    bonusCode: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetCustomers = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({}),
  responseDataSchema: z
    .object({
      _id: z.instanceof(ObjectId),
      name: z.string(),
      plan: PlanSchema,
    })
    .array(),
} satisfies ApiDefinition;

export const ClayExport = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    query: z.optional(AccountQuerySchema),
    webhookUrl: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetModelTestDefinition = {
  method: 'get',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
  }),
  responseDataSchema: ModelTestDefinitionSchema.extend({
    lists: z.array(TestListSchema.and(z.object(WithIdSchema))),
  }).nullable(),
} satisfies ApiDefinition;

export const GetModelTestListResults = {
  method: 'get',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
    listId: ObjectIdSchema,
  }),
  responseDataSchema: TierCountsSchema,
} satisfies ApiDefinition;

export const MaxModelTestLists = 10;
export const MaxModelTestAccounts = 12_000;
export const PostModelTestList = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    label: z.preprocess(
      (v) => String(v).trim(),
      z
        .string()
        .regex(TestListName.pattern)
        .min(TestListName.minLength)
        .max(TestListName.maxLength)
    ),
    domains: z.string().array().max(MaxModelTestAccounts),
    marketId: ObjectIdSchema,
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const CreateModelTestControlList = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const RefreshModelTestControlList = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
    listId: ObjectIdSchema,
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const DeleteModelTestList = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
    listId: ObjectIdSchema,
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetModelTestResults = {
  method: 'get',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    marketId: ObjectIdSchema,
  }),
  responseDataSchema: GetModelTestResultsResponseSchema,
} satisfies ApiDefinition;

export const GetTestList = {
  method: 'get',
  options: {access: 'paid'},
  requestDataSchema: z.object({
    listId: ObjectIdSchema,
  }),
  responseDataSchema: TestListSchema.and(z.object(WithIdSchema)),
} satisfies ApiDefinition;

// TODO: move this const if we request/response types in their own file
export const MaxValidateDomainRows = 5_000;
export const ValidateDomains = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.array(
    z
      .object({
        domain: z.string(),
      })
      .catchall(z.unknown())
  ),
  responseDataSchema: z
    .object({
      domain: z.string(),
      finalDomain: z.string().optional(),
      status: z.string(),
    })
    .catchall(z.unknown())
    .array(),
} satisfies ApiDefinition;

export const PostMarket = {
  method: 'post',
  options: {access: 'all'},
  requestDataSchema: z.object({
    marketId: z.instanceof(ObjectId),
    samDefinition: SamDefinitionSchema.optional(),
    scoringModel: ScoringModelSchema.partial().optional(),
    triggerRefresh: z.boolean().optional(),
  }),
  responseDataSchema: MarketSchema,
} satisfies ApiDefinition;

export const GetLatestScoringRun = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({}),
  responseDataSchema: ScoringRunSchema.pick({
    enrichedFieldCounts: true,
    timestamp: true,
  })
    .extend(WithIdSchema)
    .optional(),
} satisfies ApiDefinition;

const creationFields = {
  id: true,
  timestamp: true,
} as const;
export const PostFieldDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.union([
    AiBooleanFieldDefinitionSchema.omit(creationFields),
    AiRatingFieldDefinitionSchema.omit(creationFields),
    AiCategoryFieldDefinitionSchema.omit(creationFields),
    CrmFieldDefinitionSchema.omit(creationFields),
  ]),
  responseDataSchema: FieldDefinitionSchema,
} satisfies ApiDefinition;

export const DeleteFieldDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    fieldId: z.instanceof(ObjectId),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const PostSignalDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: SignalDefinitionSchema.omit(creationFields),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const SaveFieldDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    fieldDefinition: FieldDefinitionSchema,
    publishVersionId: ObjectIdSchema.optional(),
  }),
  responseDataSchema: FieldDefinitionSchema,
} satisfies ApiDefinition;

export const DeleteSignalDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    signalId: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetNumAccountsToEnrichDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    accountSelection: AccountSelectionSchema,
    fieldDefinitionId: ObjectIdSchema,
    overrideFieldValues: z.boolean(),
  }),
  responseDataSchema: z.object({
    creditsNeeded: z.number(),
    numAccounts: z.number(),
  }),
} satisfies ApiDefinition;

export const RunEnrichmentDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    accountSelection: AccountSelectionSchema,
    fieldDefinitionId: ObjectIdSchema,
    overrideFieldValues: z.boolean(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetEnrichmentPreviewResultsDefinition = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    fieldDefinitionId: ObjectIdSchema,
    versionId: ObjectIdSchema,
  }),
  responseDataSchema: z
    .object({
      accountId: ObjectIdSchema,
      value: z.unknown(),
      reasoning: z.string().optional(),
    })
    .array(),
} satisfies ApiDefinition;

export const RunEnrichmentPreviewDefinition = {
  method: 'post',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    fieldDefinitionId: ObjectIdSchema,
    versionId: ObjectIdSchema,
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetAccountsWithLogos: BatchedApiDefinition<
  {accountIds: ObjectId[]},
  AccountWithLogo[],
  ObjectId,
  AccountWithLogo
> = {
  method: 'get',
  options: {access: 'all'},
  requestDataSchema: z.object({
    accountIds: ObjectIdSchema.array(),
  }),
  responseDataSchema: AccountWithLogoSchema.array(),
  itemSchema: ObjectIdSchema,
  itemResponseDataSchema: AccountWithLogoSchema,

  createParams: (accountIds) => ({
    accountIds,
  }),
  resolveItem: (accounts, accountId) =>
    accounts.find(({_id}) => _id.equals(accountId)) ?? null,
};

export const GetKeyplayUsersMaxBatchSize = 50;
export const GetKeyplayUsers: BatchedApiDefinition<
  {userIds: string[]},
  KeyplayUser[],
  string,
  KeyplayUser
> = {
  method: 'get',
  options: {access: 'admin'},
  requestDataSchema: z.object({
    userIds: z.string().array(),
  }),
  responseDataSchema: KeyplayUserSchema.array(),
  itemSchema: z.string(),
  itemResponseDataSchema: KeyplayUserSchema,

  maxBatchSize: GetKeyplayUsersMaxBatchSize,
  createParams: (userIds) => ({
    userIds,
  }),
  resolveItem: (users, userId) => users.find(({id}) => id === userId) ?? null,
};
