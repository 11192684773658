import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {TaskStatusRequest, TaskStatusResponse} from '../../shared/api/api';
import {TaskState} from '../../shared/task';
import {useState} from 'react';

type TaskStatusOptions = {
  enabled?: boolean;
  taskType: TaskStatusRequest['type'] | undefined;
  onTaskComplete?: (state: TaskState) => void;
};

export const TaskStatusQueryKeys = {
  all: ['taskStatus'],
  task: (taskType: TaskStatusRequest['type'] | undefined) => [
    ...TaskStatusQueryKeys.all,
    taskType,
  ],
} as const;

export function useTaskStatus({
  enabled,
  taskType,
  onTaskComplete,
}: TaskStatusOptions) {
  const keyplayApi = useKeyplayApi();
  const [isTaskInProgress, setTaskInProgress] = useState(false);

  const taskStatus = useQuery<TaskStatusResponse>(
    TaskStatusQueryKeys.task(taskType),
    () => keyplayApi('/task', {params: {type: taskType}}),
    {
      enabled: !!taskType && enabled,
      onSuccess: (data) => {
        const task = data.task;
        if (!task) {
          return;
        }

        setTaskInProgress((prevTaskInProgress) => {
          const taskInProgress =
            task.state === 'pending' || task.state === 'running';
          if (prevTaskInProgress && !taskInProgress) {
            onTaskComplete?.(task.state);
          }

          return taskInProgress;
        });
      },
      refetchInterval: (data) => {
        // Refetch more often while the task is pending or running.
        if (
          data?.task?.state === 'pending' ||
          data?.task?.state === 'running'
        ) {
          return 10_000;
        }

        return 60_000;
      },
    }
  );

  return {
    taskStatus,
    isTaskInProgress,
  };
}
