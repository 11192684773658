import React, {createContext, useContext} from 'react';
import {FieldDefinition} from '../../../shared/enrichment';

const FieldDefinitionContext = createContext<FieldDefinition | null>(null);

export const FieldDefinitionProvider = ({
  children,
  fieldDefinition,
}: React.PropsWithChildren<{fieldDefinition: FieldDefinition}>) => {
  return (
    <FieldDefinitionContext.Provider value={fieldDefinition}>
      {children}
    </FieldDefinitionContext.Provider>
  );
};

export const useFieldDefinitionFromContext = () => {
  const fieldDefinition = useContext(FieldDefinitionContext);
  if (!fieldDefinition) {
    throw new Error('FieldDefinitionContext not found');
  }

  return fieldDefinition;
};
