import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  HTMLChakraProps,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import {useIsFeatureEnabled} from '../../hooks/api/metadata';
import {IntegrationStatus} from '../../shared/integrations';
import {keys} from '../../shared/util';
import {useNavigate} from 'react-router-dom';
import {AllIntegrations, Integration} from './AllIntegrations';
import {useIntegrations} from '../../hooks/api/integrations';

const IntegrationsList = () => {
  const hubspot = useIsFeatureEnabled('hubspot');
  const salesforce = useIsFeatureEnabled('salesforce');
  const {data, isLoading, isError} = useIntegrations();

  if (isLoading) {
    return (
      <Center>
        <Spinner marginX="auto" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center>
        <Alert status="error" maxWidth="1048px">
          <AlertIcon />
          There was an error fetching your integrations.
        </Alert>
      </Center>
    );
  }

  // Pick out the first connected CRMs (if any). This should be fine since we don't allow multiple
  // integrations to be enabled. We use this to disable certain integrations so the user doesn't
  // accidentally enable incompatible ones.
  const crmConnected = data[keys(data)[0]];

  return (
    <Flex gap={4} wrap={'wrap'}>
      <Card
        detailsUrl="salesforce"
        integration={AllIntegrations.salesforce}
        status={data.salesforce?.status}
        isDisabled={
          !salesforce || (crmConnected && crmConnected.type !== 'salesforce')
        }
      />
      <Card
        detailsUrl="hubspot"
        integration={AllIntegrations.hubspot}
        status={data.hubspot?.status}
        isDisabled={
          !hubspot || (crmConnected && crmConnected.type !== 'hubspot')
        }
      />
    </Flex>
  );
};

interface CardProps {
  integration: Integration;
  status?: IntegrationStatus;
  isDisabled?: boolean;
  detailsUrl?: string;
}

const Card = ({detailsUrl, integration, status, isDisabled}: CardProps) => {
  const navigate = useNavigate();

  let buttonText, buttonProps: ButtonProps & HTMLChakraProps<'a'>;
  if (status === 'active') {
    buttonText = 'Connected';
    buttonProps = {
      colorScheme: 'kbuttongreen',
      color: 'kgreen.400',
      isDisabled: true,
      // hack: use inline style to override disabled button css
      // this case isn't really a button
      style: {opacity: 1, cursor: 'default'},
    };
  } else if (status === 'refresh_token_expired') {
    // refresh_token_expired
    buttonText = 'Reactivate';
    buttonProps = {
      colorScheme: 'kbuttonblue',
      onClick: (e) => {
        e.stopPropagation();
        location.assign(integration.oauthUrl);
      },
    };
  } else {
    buttonText = 'Connect';
    buttonProps = {
      colorScheme: 'kbuttonblue',
      cursor: 'pointer',
      onClick: (e) => {
        e.stopPropagation();
        location.assign(integration.oauthUrl);
      },
    };
  }

  return (
    <Flex
      direction="column"
      position="relative"
      borderColor={'kgray.200'}
      borderWidth="1px"
      borderRadius="8px"
      p={6}
      w={'320px'}
      {...(detailsUrl && status
        ? {
            onClick: () => navigate(detailsUrl),
            cursor: 'pointer',
            _hover: {bgColor: 'kgray.100'},
          }
        : {})}
    >
      {!isDisabled && (
        <Button position="absolute" top={4} right={4} {...buttonProps}>
          {buttonText}
        </Button>
      )}
      <Flex width="75px" height="75px" marginBottom={4}>
        {integration.logo}
      </Flex>
      <Box marginBottom={2} fontSize="16px" lineHeight="1" fontWeight={500}>
        {integration.label}
      </Box>
      <Box fontSize="14px" color="kgray.300">
        {integration.description}
      </Box>
    </Flex>
  );
};

export default IntegrationsList;
