import React from 'react';
import {Text, TextProps} from '@chakra-ui/react';

export const Label = ({
  children,
  ...props
}: React.PropsWithChildren<TextProps>) => (
  <Text
    fontSize="sm"
    textColor="kgray.300"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Text>
);
