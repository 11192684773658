import React, {useState} from 'react';
import {
  Button,
  Divider,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  useSignalBuilderNavStore,
  useSignalBuilderStore,
} from './SignalBuilder.state';
import {useCustomer} from '../../../hooks/api/metadata';
import Select from 'react-select';
import {
  ScoringCategories,
  ScoringCategoryLabels,
} from '../../../shared/signals';
import _ from 'lodash';
import {useCreateSignalDefinition} from '../../../hooks/api/signalDefinitions';
import {ObjectId} from 'bson';
import {RangeSignalBuilder} from './RangeSignalBuilder';
import {Label} from './helpers';
import {FieldDefinition} from '../../../shared/enrichment';
import {
  FieldDefinitionProvider,
  useFieldDefinitionFromContext,
} from './FieldDefinitionContext';
import {BooleanSignalBuilder} from './BooleanSignalBuilder';
import {
  getSignalEvalFnInputs,
  SignalDefinition,
  SignalEvalFn,
} from '../../../shared/signalDefinition';

export const CreateSignalModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {setStep} = useSignalBuilderNavStore();
  const {reset} = useSignalBuilderStore();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        reset();
        setStep('selectField');
      }}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <CreateSignalModalContent onClose={onClose} />
    </Modal>
  );
};

const CreateSignalModalContent = ({onClose}: {onClose: () => void}) => {
  const {step} = useSignalBuilderNavStore();
  const {reset} = useSignalBuilderStore();
  const [selectedField, setSelectedField] = useState<FieldDefinition | null>(
    null
  );

  return (
    <ModalContent>
      <ModalCloseButton />
      {step === 'selectField' && (
        <SelectFieldStep
          selectedField={selectedField}
          onFieldSelected={(selectedField) => {
            setSelectedField(selectedField);
            reset();
          }}
        />
      )}
      {step === 'defineSignal' && !!selectedField && (
        <FieldDefinitionProvider fieldDefinition={selectedField}>
          <DefineSignalStep onClose={onClose} />
        </FieldDefinitionProvider>
      )}
    </ModalContent>
  );
};

const SelectFieldStep = ({
  selectedField,
  onFieldSelected,
}: {
  selectedField: FieldDefinition | null;
  onFieldSelected: (field: FieldDefinition) => void;
}) => {
  const {setStep} = useSignalBuilderNavStore();

  const customer = useCustomer();
  const options =
    customer.fieldDefinitions
      ?.filter(({dataType}) => dataType === 'number' || dataType === 'boolean')
      ?.filter(({status}) => status === 'published')
      ?.map((field) => ({
        value: field.id,
        label: field.label,
        field,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Select Signal Source
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid
          templateColumns="1fr 200px"
          columnGap="8"
          rowGap="2"
          alignItems="baseline"
        >
          <GridItem>
            <Label>Field</Label>
          </GridItem>
          <GridItem>
            <Label>Field Type</Label>
          </GridItem>

          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                onFieldSelected(newValue.field);
              }}
              options={options}
              placeholder="Select a Field"
              value={options.find(
                ({value}) => value.toString() === selectedField?.id.toString()
              )}
            />
          </GridItem>
          <GridItem>{selectedField?.dataType ?? '-'}</GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={!selectedField}
          onClick={() => setStep('defineSignal')}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
};

const categoryOptions = ScoringCategories.map((category) => ({
  value: category,
  label: ScoringCategoryLabels[category],
}));

const typeGroups: SignalEvalFn['type'][][] = [['range'], ['isTrue', 'isFalse']];

function findGroupId({
  evalFn,
  selectedField,
  signalDefinitions,
}: {
  evalFn: SignalEvalFn;
  selectedField: FieldDefinition;
  signalDefinitions: SignalDefinition[];
}) {
  const groups = typeGroups.find((group) => group.includes(evalFn.type));
  if (!groups) {
    return;
  }

  return (
    signalDefinitions.find(
      (signalDefinition) =>
        groups.includes(signalDefinition.evalFn.type) &&
        getSignalEvalFnInputs(signalDefinition.evalFn).every(
          (input) =>
            input.type === 'field' && selectedField.id.equals(input.field)
        )
    )?.groupId ?? new ObjectId()
  );
}

const DefineSignalStep = ({onClose}: {onClose: () => void}) => {
  const {setStep} = useSignalBuilderNavStore();
  const {evalFn, label, setSignalCategory, signalCategory} =
    useSignalBuilderStore();
  const selectedField = useFieldDefinitionFromContext();

  const {signalDefinitions} = useCustomer();
  const createSignalDefinition = useCreateSignalDefinition();
  const saveSignalDefinition = () => {
    if (!signalCategory || !label || !evalFn) {
      return;
    }

    const groupId = findGroupId({
      evalFn,
      selectedField,
      signalDefinitions: signalDefinitions ?? [],
    });

    createSignalDefinition.mutate(
      {
        label,
        category: signalCategory,
        evalFn,
        groupId,
      },
      {onSuccess: onClose}
    );
  };

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Define Signals - {selectedField?.label}
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid columnGap="8" rowGap="2" templateColumns="60% 1fr">
          <GridItem>
            <Label>Category</Label>
          </GridItem>
          <GridItem />
          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                setSignalCategory(newValue.value);
              }}
              options={categoryOptions}
              placeholder="Select a category"
              value={categoryOptions.find(
                ({value}) => value === signalCategory
              )}
            />
          </GridItem>
          <GridItem />
          <GridItem colSpan={2}>
            <Divider my="5" />
          </GridItem>
          {selectedField?.dataType === 'number' && <RangeSignalBuilder />}
          {selectedField?.dataType === 'boolean' && <BooleanSignalBuilder />}
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
        gap="2"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={!signalCategory || !label || !evalFn}
          isLoading={createSignalDefinition.isLoading}
          onClick={saveSignalDefinition}
        >
          Save Signals
        </Button>
        <Button
          fontWeight="normal"
          onClick={() => setStep('selectField')}
          variant="ghost"
        >
          Back
        </Button>
      </ModalFooter>
    </>
  );
};
