import React, {useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import {TwoColumnGrid} from './helpers';
import Select, {components, OptionProps} from 'react-select';
import {AiFieldDefinition, AiFieldType} from '../../../shared/enrichment';
import {defaultSelectStyles} from '../../theme/select';
import {useCreateFieldDefinition} from '../../../hooks/api/fieldDefinitions';
import {assertNever, DistributedOmit} from '../../../shared/util';
import {WrapperFlex, ScrollableFlex} from '../../utils/scrolling';
import {
  AiFieldBuilderPreviewTable,
  SamplePreviewTable,
} from './AiFieldBuilderPreviewTable';

interface FieldTypeOption {
  label: string;
  description: string;
  value: AiFieldType;
}

const fieldTypeOptions = [
  {
    label: '1-10 Rating',
    description: 'For questions like "How likely is..." or "How much does..."',
    value: 'rating',
  },
  {
    label: 'True/False',
    description:
      'For questions like "Does this company..." or "Is this company..."',
    value: 'boolean',
  },
] satisfies FieldTypeOption[];

const FieldTypeOption = (props: OptionProps<FieldTypeOption>) => {
  const {label, description} = props.data as FieldTypeOption;
  return (
    <components.Option {...props}>
      <Text fontSize="14">{label}</Text>
      <Text fontSize="12" mt="2" fontWeight="thin" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const fieldTypeSelectStyles = defaultSelectStyles<FieldTypeOption, false>();

export const AiFieldBuilderDefineFieldStep = ({
  fieldDefinition,
  onContinue,
}: {
  fieldDefinition?: AiFieldDefinition;
  onContinue: (fieldDefinition: AiFieldDefinition) => void;
}) => {
  const createFieldDefinition = useCreateFieldDefinition();

  const [fieldName, setFieldName] = useState(fieldDefinition?.label ?? '');
  const [fieldType, setFieldType] = useState(fieldDefinition?.config.fieldType);

  const getFieldDefinition = (
    fieldType: AiFieldType
  ): DistributedOmit<AiFieldDefinition, 'id' | 'timestamp'> => {
    const commonConfig = {
      analysisFields: [],
      model: 'gpt-4o-mini' as const,
    };

    switch (fieldType) {
      case 'boolean':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'boolean',
          config: {
            ...commonConfig,
            fieldType,
            prompt: '',
          },
        };
      case 'rating':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'number',
          config: {
            ...commonConfig,
            fieldType,
            min: 1,
            max: 10,
            prompt: '',
          },
        };
      case 'category':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'string',
          config: {
            ...commonConfig,
            fieldType,
            categoryName: fieldName,
            categories: [],
          },
        };
      default:
        assertNever(fieldType);
    }
  };

  const maybeSaveFieldDefinition = () => {
    if (fieldDefinition) {
      onContinue(fieldDefinition);
      return;
    }

    if (!fieldType) {
      return;
    }

    createFieldDefinition.mutate(getFieldDefinition(fieldType), {
      onSuccess: (fieldDefinition) => {
        if (fieldDefinition.type !== 'ai') {
          throw new Error('Expected AI field definition');
        }

        onContinue(fieldDefinition);
      },
    });
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Flex direction="column" flex="1" gap="6" p="6">
          <Text fontSize="xl">Define your field</Text>
          <FormControl>
            <FormLabel>Field name</FormLabel>
            <Input
              isDisabled={!!fieldDefinition}
              type="text"
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Field type</FormLabel>
            <Select
              components={{Option: FieldTypeOption}}
              isDisabled={!!fieldDefinition}
              isMulti={false}
              isSearchable={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                setFieldType(newValue.value);
              }}
              options={fieldTypeOptions}
              placeholder="Select..."
              styles={fieldTypeSelectStyles}
              value={fieldTypeOptions.find(
                (option) => option.value === fieldType
              )}
            />
          </FormControl>
        </Flex>

        <Divider />

        <Flex justifyContent="end" w="100%" px={10} py={4} gap={10}>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!fieldName.trim() || !fieldType}
            isLoading={createFieldDefinition.isLoading}
            onClick={maybeSaveFieldDefinition}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <Flex direction="column" p="6" gap="8">
        {fieldDefinition ? (
          <>
            <Text fontSize="xl">Preview</Text>
            <Box flex="1" position="relative">
              <WrapperFlex>
                <ScrollableFlex p="0">
                  <AiFieldBuilderPreviewTable />
                </ScrollableFlex>
              </WrapperFlex>
            </Box>
          </>
        ) : (
          <>
            <Flex direction="column" gap="2">
              <Text fontSize="xl">Preview</Text>
              <Text fontSize="md" color="kgray.300">
                You can choose to customize the accounts you see here in the
                next step.
              </Text>
            </Flex>
            <Box flex="1" position="relative">
              <WrapperFlex>
                <ScrollableFlex p="0">
                  <SamplePreviewTable />
                </ScrollableFlex>
              </WrapperFlex>
            </Box>
          </>
        )}
      </Flex>
    </TwoColumnGrid>
  );
};
