import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Tooltip,
  useModalContext,
} from '@chakra-ui/react';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {TwoColumnGrid} from './helpers';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {assertNever} from '../../../shared/util';
import {PromptConfigEditor} from './PromptConfigEditor';
import {AiFieldDefinition} from '../../../shared/enrichment';
import {ObjectId} from 'bson';
import {AiFieldBuilderPreviewTable} from './AiFieldBuilderPreviewTable';
import {
  useIsEnrichmentPreviewTaskRunning,
  useRunEnrichmentPreview,
} from '../../../hooks/api/fieldDefinitionPreview';
import {useSelectedVersionStore} from './SelectedVersion.state';

export const AiFieldBuilderPreviewResultsStep = () => {
  const {prevStep} = useAiFieldBuilderNavStore();
  const {publishChanges, serverState, versionConfigs} =
    useAiFieldBuilderStore();
  const {isTaskInProgress: isEnrichmentPreviewTaskRunning} =
    useIsEnrichmentPreviewTaskRunning();
  const {selectedVersionId} = useSelectedVersionStore();

  const {onClose} = useModalContext();
  // Keep track of running state locally to avoid button style flashing
  const [isEnrichmentPreviewRunning, setIsEnrichmentPreviewRunning] = useState(
    isEnrichmentPreviewTaskRunning
  );
  useEffect(() => {
    setIsEnrichmentPreviewRunning(isEnrichmentPreviewTaskRunning);
  }, [isEnrichmentPreviewTaskRunning]);

  const runEnrichmentPreview = useRunEnrichmentPreview();
  const saveAndStartEnrichmentPreview = async () => {
    setIsEnrichmentPreviewRunning(true);
    await publishChanges.mutateAsync({});
    await runEnrichmentPreview.mutateAsync({
      fieldDefinitionId: serverState.id,
      versionId: selectedVersionId,
    });
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <Flex direction="column" flex="1" gap="6" p="6">
                <Text fontSize="xl">Write your prompt</Text>
                <VersionConfigEditor
                  versionId={selectedVersionId}
                  config={versionConfigs[selectedVersionId.toString()]}
                />
              </Flex>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4} gap={10}>
          <Button onClick={prevStep} variant="ghost">
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isLoading}
            onClick={() => {
              publishChanges.mutate(
                {publishVersionId: selectedVersionId},
                {onSuccess: onClose}
              );
            }}
          >
            Publish & Exit
          </Button>
        </Flex>
      </Flex>

      <Flex p="6" direction="column" gap="8">
        <Flex alignItems="baseline" justifyContent="space-between">
          <Text fontSize="xl">Preview</Text>
          <Tooltip
            isDisabled={!isEnrichmentPreviewRunning}
            label={'Enrichment preview is currently running.'}
            placement="top"
          >
            <Button
              colorScheme="kbuttonblue"
              isLoading={isEnrichmentPreviewRunning}
              onClick={saveAndStartEnrichmentPreview}
            >
              Run Prompt to Preview
            </Button>
          </Tooltip>
        </Flex>

        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <AiFieldBuilderPreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </Flex>
    </TwoColumnGrid>
  );
};

const VersionConfigEditor = ({
  versionId,
  config,
}: {
  versionId: ObjectId;
  config: AiFieldDefinition['config'];
}) => {
  let configEditor = <></>;
  switch (config.fieldType) {
    // These use the same config editor for now
    case 'boolean':
    case 'rating':
      configEditor = (
        <PromptConfigEditor versionId={versionId} config={config} />
      );
      break;

    case 'category':
      configEditor = <></>;
      break;

    default:
      assertNever(config);
  }

  return <Flex direction="column">{configEditor}</Flex>;
};
