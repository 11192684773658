import {z} from 'zod';
import {ObjectIdSchema} from './zod';

const BaseTestListSchema = z.object({
  timestamp: z.date(),
  customerId: ObjectIdSchema,
  type: z.string(),
  label: z.string(),

  status: z.union([
    z.object({type: z.literal('processing')}),
    z.object({
      type: z.literal('ready'),
      numAccounts: z.number(),
    }),
  ]),
});

const UploadedTestListSchema = BaseTestListSchema.extend({
  type: z.literal('uploaded'),
  numUploadedRows: z.number(),
});
export type UploadedTestList = z.infer<typeof UploadedTestListSchema>;

const SamSampleTestListSchema = BaseTestListSchema.extend({
  type: z.literal('samSample'),
  marketId: ObjectIdSchema,
  lastRefreshed: z.date().optional(),
});
export type SamSampleTestList = z.infer<typeof SamSampleTestListSchema>;

export const TestListSchema = z.union([
  UploadedTestListSchema,
  SamSampleTestListSchema,
]);
export type TestList = z.infer<typeof TestListSchema>;

const TestListAccountSchema = z.object({
  listId: ObjectIdSchema,
  customerId: ObjectIdSchema,
  accountId: ObjectIdSchema,
});
export type TestListAccount = z.infer<typeof TestListAccountSchema>;
