import React from 'react';
import {
  AiBooleanFieldDefinitionConfig,
  AiRatingFieldDefinitionConfig,
  AnalysisFieldLabels,
  AnalysisFields,
} from '../../../shared/enrichment';
import Select from 'react-select';
import {ObjectId} from 'bson';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  FormHelperText,
  Divider,
} from '@chakra-ui/react';

const AnalysisFieldOptions = AnalysisFields.map((analysisField) => ({
  label: AnalysisFieldLabels[analysisField],
  value: analysisField,
}));

export const PromptConfigEditor = ({
  config,
  versionId,
}: {
  config: AiBooleanFieldDefinitionConfig | AiRatingFieldDefinitionConfig;
  versionId: ObjectId;
}) => {
  const {updateVersionConfig} = useAiFieldBuilderStore();
  return (
    <Flex direction="column" gap="6">
      <FormControl>
        <FormLabel textTransform="uppercase">Prompt</FormLabel>
        <Textarea
          onChange={(e) => {
            updateVersionConfig(versionId, {
              ...config,
              prompt: e.target.value,
            });
          }}
          rows={5}
          placeholder="Ask your AI analyst anything..."
          value={config.prompt}
        />
        <FormHelperText textColor="kgray.300">
          Prompting is most successful when you phrase your prompt as a question
          about a single account, like “Does this company have brick and mortar
          locations?”
        </FormHelperText>
      </FormControl>

      <Divider />

      <FormControl>
        <FormLabel textTransform="uppercase">
          Answer based on analysis of
        </FormLabel>
        <Select
          isMulti={true}
          onChange={(options) => {
            updateVersionConfig(versionId, {
              ...config,
              analysisFields: options.map(({value}) => value),
            });
          }}
          options={AnalysisFieldOptions}
          placeholder="Select fields"
          value={AnalysisFieldOptions.filter(({value}) =>
            config.analysisFields.includes(value)
          )}
        />
        <FormHelperText textColor="kgray.300">
          Your AI Analyst will look at fields you already have in Keyplay in
          order to answer your question. Add as many fields as are relevant.
        </FormHelperText>
      </FormControl>

      <Divider />

      <FormControl>
        <FormLabel textTransform="uppercase">
          Key Clarifications (optional)
        </FormLabel>
        <Textarea
          onChange={(e) => {
            updateVersionConfig(versionId, {
              ...config,
              clarifications: e.target.value.split('\n'),
            });
          }}
          placeholder="Add additional instructions for your AI Analyst..."
          rows={5}
          value={config.clarifications?.join('\n') ?? ''}
        />
        <FormHelperText textColor="kgray.300">
          Add a new line for each instruction you’d like your AI Analyst to take
          into consideration.
        </FormHelperText>
      </FormControl>
    </Flex>
  );
};
